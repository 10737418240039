import { NgModule } from '@angular/core';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
  imports: [
    MatProgressSpinnerModule,
    MatSnackBarModule
  ],
  exports: [
    MatProgressSpinnerModule,
    MatSnackBarModule
  ]
})
export class FmeMaterialModule { }
