import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[noComma]'
})
export class NoCommaDirective {

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.key === ',') {
      event.preventDefault();
    }
  }
}