import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { FmeAuthService } from "../services/auth.service";
import { NavigationService } from "../services/common/navigation.service";
import { EXTERNAL_SIGN_UP } from "src/app/auth/enums/external-user-sign-in.enum";

export const LoggedInGuard = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const authService = inject(FmeAuthService);
    const router = inject(Router);
    const navService = inject(NavigationService);
    if (route.queryParams[EXTERNAL_SIGN_UP.TOKEN]) {
        authService.logOutForExternal();
    }
    if (authService.isLoggedIn) {
        const prevUrl = navService.getPreviousUrl();
        if (prevUrl) {
            router.navigate([prevUrl]);
        }
        return false;
    }
    return true;

}
