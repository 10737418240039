import { NgModule } from '@angular/core';
import { LifestyleFormValidPipe } from './form/lifestyle-form-valid.pipe';
import { ObjHaveValidFieldsPipe } from './objHaveValidFields.pipe';
import { FindTemplatePipe } from './findTemplate.pipe';
import { IsEmptyObjPipe } from './isEmptyObj.pipe';
import { SafePipe } from './safe-url.pipe';
import { LastArrayItemPipe } from './last-array-item.pipe';
import { PassSpecificFieldsPipe } from './specific-fields.pipe';

@NgModule({
  declarations: [LifestyleFormValidPipe, ObjHaveValidFieldsPipe, FindTemplatePipe, IsEmptyObjPipe, SafePipe, LastArrayItemPipe, PassSpecificFieldsPipe],
  exports: [LifestyleFormValidPipe, ObjHaveValidFieldsPipe, FindTemplatePipe, IsEmptyObjPipe, SafePipe, LastArrayItemPipe, PassSpecificFieldsPipe],
})
export class FmePipesModule {}
