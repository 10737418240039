import { NgModule } from '@angular/core';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { FmeMaterialModule } from './modules/mat-components.module';
import { CommonModule } from '@angular/common';
import { BackButtonDirective } from './directives/back-button.directive';
import { ShowScrollOnScrollDirective } from './directives/no-scroll.directive';
import { TemplateDirective } from './directives/template.directive';
import { FmePipesModule } from './pipes/fme-pipes.module';
import { CheckOverflowOnHoverDirective } from './directives/check-overflow-on-hover.directive';
import { NoCommaDirective } from './directives/no-comma.directive';
import { FmeTranslateModule } from './modules/translate.module';
import { ShowTooltipOnOverflowDirective } from './directives/show-tooltip-on-overflow.directive';

@NgModule({
  declarations: [
    ClickOutsideDirective,
    BackButtonDirective,
    ShowScrollOnScrollDirective,
    TemplateDirective,
    CheckOverflowOnHoverDirective,
    NoCommaDirective,
    ShowTooltipOnOverflowDirective,
  ],
  imports: [FmeMaterialModule, CommonModule, FmePipesModule, FmeTranslateModule],
  exports: [
    ClickOutsideDirective,
    FmeMaterialModule,
    CommonModule,
    BackButtonDirective,
    ShowScrollOnScrollDirective,
    FmePipesModule,
    TemplateDirective,
    CheckOverflowOnHoverDirective,
    NoCommaDirective,
    FmeTranslateModule,
    ShowTooltipOnOverflowDirective
  ],
})
export class FmeSharedModule {}
