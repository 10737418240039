import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subscription, fromEvent, map, merge } from 'rxjs';

@Directive({
  selector: '[checkOverflowOnHover]',
})
export class CheckOverflowOnHoverDirective implements AfterViewInit, OnInit, OnDestroy {
  @Output() isOverflows: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() childClasses: string[];
  @Input() addClassOnOverflow: string;

  sub: Subscription;
  constructor(
    private el: ElementRef,
  ) {}

  ngOnInit(): void {
    this.mouseEvent$();
  }
  ngAfterViewInit(): void {
    this.elementsOverflowing();
  }
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  private mouseEvent$(): void {
    const mouseOverEvent$ = fromEvent(this.el.nativeElement, 'mouseover').pipe(map(() => 'over'));
    const mouseLeaveEvent$ = fromEvent(this.el.nativeElement, 'mouseleave').pipe(map(() => 'leave'));
    this.sub = merge(mouseOverEvent$, mouseLeaveEvent$).subscribe((event: 'over' | 'leave') => {
      if (event === 'over') {
        if(this.elementsOverflowing()) {
          if(this.addClassOnOverflow) this.el.nativeElement.classList.add(this.addClassOnOverflow);
          this.isOverflows.emit(true);
        }
      }
      if(event === 'leave') {
        if(this.addClassOnOverflow) this.el.nativeElement.classList.remove(this.addClassOnOverflow);
        this.isOverflows.emit(false);
      }
    });
  }
  private elementsOverflowing(): boolean {
    if(!this.childClasses?.length) return false;
    const result: boolean = this.childClasses?.some((className: string) => this.checkChildOverflow(className));
    if(result) return true;
    else return false;
  }
  private checkChildOverflow(className: string): boolean {
    const container = this.el.nativeElement;
    const overflowElements = container.querySelectorAll(`.${className}`);
    if(!overflowElements?.length) return false;
    return Array.from(overflowElements).some((el: HTMLElement) => this.elementOverflows(el))
  }
  private elementOverflows(el: HTMLElement): boolean {
    return el.scrollWidth > el.clientWidth;
  }
}
