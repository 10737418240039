import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LANGUAGE_KEY } from '../../enums/language.enum';

@Injectable({ providedIn: 'root' })
export class FmeTranslateService {

    private readonly languages: string[] = ['en', 'es', 'nb'];

    constructor(
        private translate: TranslateService
    ) {}

    init() {
        const savedLang: string | undefined = localStorage.getItem(LANGUAGE_KEY.LANG);
        this.translate.addLangs(this.languages);
        this.translate.setDefaultLang('en');
        this.translate.use(savedLang || 'en');
        console.log('inited in add');
    }

    setTransLanguage(lang: 'en' | 'es' | 'nb'){
        localStorage.setItem(LANGUAGE_KEY.LANG, lang);
        this.translate.use(lang);
    }

    getCurrentLang(): string {
        return this.translate.currentLang ? this.translate.currentLang.toUpperCase() : 'EN';
    }

    getTransLanguage(){
        return this.translate.getLangs();
    }

}
