import { Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Subscription, debounceTime, fromEvent, tap } from 'rxjs';

@Directive({
  selector: '[showScrollOnScroll]',
})
export class ShowScrollOnScrollDirective implements OnInit, OnDestroy {
  scrollObserver: Subscription;
  private readonly className: string = 'fme-invisible-scrollbar';

  constructor(
    private tempalte: ElementRef,
  ) {
    this.scrollObserver = fromEvent(this.tempalte.nativeElement, 'scroll')
      .pipe(
        tap((event: any) => {
            if (this.tempalte.nativeElement.classList.contains(this.className)) {
                this.tempalte.nativeElement.classList.remove(this.className);
            }
        }),
        debounceTime(1400),
      )
      .subscribe((data: any) => {
        this.tempalte.nativeElement.classList.add(this.className);
      });
  }

  ngOnInit(): void {
    this.tempalte.nativeElement.classList.add(this.className);
  }

  ngOnDestroy(): void {
    this.scrollObserver.unsubscribe();
  }
}
