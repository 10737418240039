import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'passSpecificFields'
})

export class PassSpecificFieldsPipe implements PipeTransform {
    transform(obj: any, fieldsToDisplay: string[]): any {
        console.log(obj, fieldsToDisplay);

        if (obj && fieldsToDisplay?.length) {
            const object: {[key: string]: string | number} = {};
            fieldsToDisplay.forEach((field: string) => {
                object[field] = obj[field];
            });
            console.log(object);
            return object;
        }
        return obj;
    }
}
