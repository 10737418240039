import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EXTERNAL_SIGN_UP } from './auth/enums/external-user-sign-in.enum';
import { FmeTranslateService } from './core/services/common/fme-translate.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'FME';
  
  constructor(private router: Router, private route: ActivatedRoute, private fmeTranslateService: FmeTranslateService) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: any) => {
      if (params[EXTERNAL_SIGN_UP.LANG]) {
          const lang = params[EXTERNAL_SIGN_UP.LANG].toLowerCase();
          this.fmeTranslateService.setTransLanguage(lang);
      }
    })
  }
}

