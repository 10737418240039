import { AriaDescriber, FocusMonitor } from '@angular/cdk/a11y';
import { Directionality } from '@angular/cdk/bidi';
import { Overlay } from '@angular/cdk/overlay';
import { Platform } from '@angular/cdk/platform';
import { ScrollDispatcher } from '@angular/cdk/scrolling';
import { DOCUMENT } from '@angular/common';
import {
  Directive,
  ElementRef,
  HostListener,
  Inject,
  Input,
  NgZone,
  Optional,
  ViewContainerRef,
} from '@angular/core';
import {
  MAT_TOOLTIP_DEFAULT_OPTIONS,
  MAT_TOOLTIP_SCROLL_STRATEGY,
  MatTooltip,
  MatTooltipDefaultOptions,
} from '@angular/material/tooltip';

@Directive({
  selector: '[showTooltipOnOverflow]',
})
export class ShowTooltipOnOverflowDirective extends MatTooltip {
  @Input() childClasses: string[];
  @Input() addClassOnOverflow: string;
  @Input() preventDisable: boolean;

  @Input() set tooltipMessage(message: string) {
    this.message = message;
  }

  elementOverflowing = false;
  override get disabled(): boolean {
    if(this.preventDisable) return false;
    return !this.elementOverflowing;
  }
  @HostListener('mouseover', ['$event']) onMouseOver() {
    this.elementOverflowing = this.elementsOverflowing();
  }
  @HostListener('mouseleave', ['$event']) onMouseLeave() {
    this.elementOverflowing = this.elementsOverflowing();
  }

  private elementsOverflowing(): boolean {
    if (!this.childClasses?.length) {
        return this.elementOverflows(this.el.nativeElement);
    }
    const result: boolean = this.childClasses?.some((className: string) => this.checkChildOverflow(className));
    if (result) return true;
    else return false;
  }
  private checkChildOverflow(className: string): boolean {
    const container = this.el.nativeElement;
    const overflowElements = container.querySelectorAll(`.${className}`);
    if (!overflowElements?.length) return false;
    return Array.from(overflowElements).some((el: HTMLElement) => this.elementOverflows(el));
  }
  private elementOverflows(el: HTMLElement): boolean {
    return el.scrollWidth > el.clientWidth;
  }

  constructor(
    private el: ElementRef,
    _overlay: Overlay,
    _elementRef: ElementRef,
    _scrollDispatcher: ScrollDispatcher,
    _viewContainerRef: ViewContainerRef,
    _ngZone: NgZone,
    _platform: Platform,
    _ariaDescriber: AriaDescriber,
    _focusMonitor: FocusMonitor,
    @Inject(MAT_TOOLTIP_SCROLL_STRATEGY) _scrollStrategy: any,
    @Inject(DOCUMENT) _document: any,
    @Optional() _dir: Directionality,
    @Optional()
    @Inject(MAT_TOOLTIP_DEFAULT_OPTIONS)
    _defaultOptions: MatTooltipDefaultOptions,
  ) {
    super(
      _overlay,
      _elementRef,
      _scrollDispatcher,
      _viewContainerRef,
      _ngZone,
      _platform,
      _ariaDescriber,
      _focusMonitor,
      _scrollStrategy,
      _dir,
      _defaultOptions,
      _document,
    );
  }
}
