import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { HttpLoaderFactory } from '../utils/http-loader-factory';
import { HttpClient } from '@angular/common/http';
import { FmeTranslateService } from '../services/common/fme-translate.service';

@NgModule({
  imports: [
    TranslateModule
  ],
  exports: [TranslateModule],
  providers: [TranslateService, FmeTranslateService]
})
export class FmeTranslateModule {}
