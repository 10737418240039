import { animate, group, style, transition, trigger } from '@angular/animations';

export const Animations = {
  animations: [
    trigger('toggle_select', [
      transition(':enter', [
        style({ transform: 'translateY(-2px)', opacity: 0 }),
        group([
          animate('0.2s ease-in-out', style({ transform: 'translateY(0px)' })),
          animate('0.2s ease-in-out', style({ opacity: 1 })),
        ]),
      ]),
      transition(':leave', [
        style({ transform: 'translateY(0px)', opacity: 1 }),
        group([
          animate('0.2s ease-in-out', style({ transform: 'translateY(-2px)' })),
          animate('0.2s ease-in-out', style({ opacity: 0 })),
        ]),
      ]),
    ]),
    trigger('toggle_menu', [
      transition(':enter', [
        style({ transform: 'translateX(-2px)', opacity: 0 }),
        group([
          animate('0.2s ease-in-out', style({ transform: 'translateX(0px)' })),
          animate('0.2s ease-in-out', style({ opacity: 1 })),
        ]),
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0px)', opacity: 1 }),
        group([
          animate('0.2s ease-in-out', style({ transform: 'translateX(-2px)' })),
          animate('0.2s ease-in-out', style({ opacity: 0 })),
        ]),
      ]),
    ]),
    trigger('toggle_error', [
      transition(':enter', [
        style({ transform: 'translateY(-10px)', opacity: 0 }),
        group([
          animate('0.2s ease-in-out', style({ transform: 'translateY(0px)' })),
          animate('0.2s ease-in-out', style({ opacity: 1 })),
        ]),
      ]),
      transition(':leave', [
        style({ transform: 'translateY(0px)', opacity: 1 }),
        group([
          animate('0.2s ease-in-out', style({ transform: 'translateY(-10px)' })),
          animate('0.2s ease-in-out', style({ opacity: 0 })),
        ]),
      ]),
    ]),
    
  ],
  
  grow: trigger('grow', [
      transition(
        ':enter',
        [
          style({ maxHeight: '{{min}}px', opacity: 0 }),
          animate('0.2s ease-in-out', style({ maxHeight: '{{max}}px', opacity: 1 })),
        ],
        { params: { min: '0', max: '100' } },
      ),
      transition(
        ':leave',
        [
          style({ maxHeight: '{{max}}px', opacity: 1 }),
          animate('0.2s ease-in-out', style({ maxHeight: '{{min}}px', opacity: 0 })),
        ],
        { params: { min: '0', max: '100' } },
      ),
  ]),
};
