import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { LoggedInGuard } from './core/guards/logged-in.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'dashboard',
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
    canActivate: [LoggedInGuard],
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./main/layout/fme-layout.module').then((m) => m.FmeLayoutModule),
    data: { role: 'professional' },
    canActivate: [AuthGuard],
  },
  {
    path: 'coo-dashboard',
    loadChildren: () => import('./main/layout/fme-layout.module').then((m) => m.FmeLayoutModule),
    data: { role: 'business' },
    canActivate: [AuthGuard],
  },
  {
    path: 'client',
    loadChildren: () => import('./client/client.module').then((m) => m.ClientModule),
  },
  {
    path: 'mobile',
    loadChildren: () => import('./main/content/mobile/mobile.module').then((m) => m.MobileModule),
  }

  // {
  //   path: 'terms',
  //   loadChildren: () => import('./main/content/terms/terms.module').then((m) => m.TermsModule),
  // },
  // {
  //   path: 'policy',
  //   loadChildren: () => import('./main/content/privacy/privacy.module').then((m) => m.PrivacyModule),
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
