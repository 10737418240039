<div class="fme-snackbar w-full flex flex-row shrink-0 relative min-h-[44px]">
    <div class="fme-snackbar-icon flex items-center justify-center w-12 shrink-0"
        [ngClass]="{ 'error': data.type === 'error', 'warning': data.type === 'warn', 'info': data.type === 'info', 'success': data.type === 'success' }"
        >
        <ng-container *ngIf="data.type === 'error'">
            <mat-icon class="!w-[18px] !h-auto" [svgIcon]="'fme:warning'"></mat-icon>
        </ng-container>
        <ng-container *ngIf="data.type === 'warn'">
            <mat-icon class="!w-[18px] !h-auto" [svgIcon]="'fme:warning'"></mat-icon>
        </ng-container>
        <ng-container *ngIf="data.type === 'info'">
            <mat-icon class="!w-[18px] !h-auto" [svgIcon]="'fme:comment-info'"></mat-icon>
        </ng-container>
        <ng-container *ngIf="data.type === 'success'">
            <mat-icon class="!w-[18px] !h-auto" [svgIcon]="'fme:success-circle'"></mat-icon>
        </ng-container>
    </div>
    <div class="fme-snackbar-body w-full flex items-center justify-start pl-3 pr-12">
        <p class="text-[14px] leading-[17px] font-[500] fme-col-n-dark3">{{data.message}}</p>
    </div>
    <div (click)="closeSnackbar()" class="fme-snackbar-close-button w-12 absolute right-0 top-0 bottom-0 flex items-center justify-center cursor-pointer">
        <mat-icon class="!w-[10px] !h-auto" [svgIcon]="'fme:close'"></mat-icon>
    </div>
</div>
